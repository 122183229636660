import Vue from 'vue'
import { decode } from '@/lib/util'

export default el => new Vue({
  name: 'FullWidth',
  el,
  data () {
    const sectionSettings = JSON.parse(decode(el.getAttribute('settings-data'))) || {
      videoUrlMobile: '',
      videoUrlDesktop: '',
      videoSubtitles: '',
      showVideoVolumeButton: false,
      autoPlayVideo: false,
      imageMobile: '',
      imageDesktop: ''
    }
    return {
      ...sectionSettings,
      breakpoints: '(max-width: 767px)|(min-width: 768px)'
    }
  },
  computed: {
    videoUrls () {
      const videoUrls = []
      if (this.videoUrlMobile) videoUrls.push(this.videoUrlMobile)
      if (this.videoUrlDesktop) videoUrls.push(this.videoUrlDesktop)
      return videoUrls
    },
    imageUrls () {
      const imageUrls = []
      if (this.imageMobile) imageUrls.push(this.imageMobile)
      if (this.imageDesktop) imageUrls.push(this.imageDesktop)
      return imageUrls
    }
  }
})